<!--信息审核-厂商资质审核-->
<template>
  <main>
    <!-- 工具条 -->
    <el-row>
      <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
        <el-form :inline="true" :model="filter" size="mini">
          <el-form-item>
            <el-select
              placeholder="审核状态"
              v-model="filter.param.Status"
              @change="getData"
              clearable>
              <el-option label="待审核" value="WAIT_TO_QUALIFY"/>
              <el-option label="已通过" value="QUALIFIED"/>
              <el-option label="未通过" value="UNQUALIFIED"/>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="filter.param.submitter"
              @change="getData"
              placeholder="提交单位"
              clearable
              filterable/>
          </el-form-item>
          <el-form-item label="选择日期">
            <el-date-picker
              type="daterange"
              v-model="filter.param.submit_date"
              @change="getData"
              range-separator="至"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="handleShowDialog('ADD')">
              新增
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <el-row>
      <el-col :span="24">
        <el-table
          :data="datalist.content"
          size="mini"
          :max-height="clientHeight"
          border
          highlight-current-row
          v-loading="loading"
          style="width: 100%">
          <el-table-column type="index" label="#" align="center" header-align="center" width="55"/>
          <el-table-column
            prop="Submitter"
            label="提交单位"
            show-overflow-tooltip
            align="center"
            header-align="center"/>
          <el-table-column
            prop="Name"
            label="设备供应单位"
            show-overflow-tooltip
            align="center"
            header-align="center"/>
          <el-table-column
            prop="ModelName"
            label="型号"
            show-overflow-tooltip
            align="center"
            header-align="center"/>
          <el-table-column
            prop="SubmitDate"
            label="提交日期"
            show-overflow-tooltip
            align="center"
            header-align="center"
            width="160"
            :formatter="dateFormat"/>
          <el-table-column
            prop="Status"
            label="审核状态"
            show-overflow-tooltip
            :formatter="StatusFormat"
            align="center"
            header-align="center"
            width="160"/>
          <el-table-column
            prop="Cause"
            label="原因"
            align="center"
            header-align="center"/>
          <!--          <el-table-column-->
          <!--            prop="Submitter"-->
          <!--            label="审核人"-->
          <!--            align="center"-->
          <!--            header-align="center"/>-->
          <el-table-column
            label="操作"
            align="center"
            header-align="center"
            width="210">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="handleShowDialog('CHECK', scope.row)">
                查看
              </el-button>
              <el-button
                type="text"
                @click="handleShowDialog('EDIT', scope.row)"
                :disabled="scope.row.Status === 'QUALIFIED'">
                修改
              </el-button>
              <el-button
                type="text"
                @click="handleShowDialog('HISTORY', scope.row)">
                记录
              </el-button>
              <el-button
                type="text"
                @click="handleDelete(scope.row)"
                :disabled="ifDisableQualify(scope.row.Status, scope.row.SubmitterId)">
                删除
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            label="审核"
            align="center"
            header-align="center"
            width="120">
            <template slot-scope="scope">
              <el-button
                @click='handleShowDialog("QUALIFY",scope.row)'
                type="primary" size="small">
                审核
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- 分页 -->
    <el-row>
      <el-col :span="24" class="toolbar">
        <el-pagination
          small
          background
          @size-change="(v) => handlePageChange(v, 'size')"
          @current-change="(v) => handlePageChange(v, 'page')"
          :current-page.sync="filter.page"
          :page-sizes="filter.pageSizes"
          layout="total, sizes, prev, pager, next, jumper"
          :total="datalist.total"
          style="display: inline-block; margin-left: 15px"
        >
        </el-pagination>
      </el-col>
    </el-row>
    <!--编辑/新增界面-->
    <el-dialog
      :title="dlg.title"
      :visible.sync="dlg.visible"
      width="30%"
      @close="handleClose"
    >
      <el-form
        size="mini"
        :rules="rules"
        ref="ruleForm"
        label-width="140px"
        :model="formData"
        :disabled="dlg.title === '查看'"
      >
        <el-form-item label="提交单位">
          <div>{{ this.submitter }}</div>
        </el-form-item>
        <el-form-item label="提交日期">
          <div>
            {{
              formData.SubmitDate ? formData.SubmitDate.split(' ')[0] : new Date(new Date().getTime()).toLocaleDateString('zh-CN').replaceAll('/', '-')
            }}
          </div>
        </el-form-item>
        <el-form-item label="设备供应单位名称" prop="Name" :error="errorMsg">
          <el-input
            placeholder="请输入设备供应单位名称"
            v-model="formData.Name"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="设备类型" prop="ModelType" :error="errorMsg">
          <el-select
            style="width: 100%"
            v-model="formData.ModelType"
            clearable>
            <el-option
              v-for="item in deviceTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="设备型号" prop="ModelName" :error="errorMsg">
          <el-input
            v-model="formData.ModelName"
            placeholder="请输入设备型号"
            clearable>
          </el-input>
        </el-form-item>

        <el-form-item prop="FileList" label="添加附件">
          <el-upload
            ref="upload"
            accept=".jpg, .pdf"
            :action="upload()"
            :on-preview="handleFilePreview"
            :on-remove="handleFileRemove"
            :on-success="handleFileSuccess"
            :before-remove="beforeFileRemove"
            :on-exceed="handleFileExceed"
            :file-list="formData.FileList"
            multiple
            :limit="4">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/pdf文件，点击文件预览</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="备注" prop="Remark">
          <el-input v-model="formData.Remark"></el-input>
        </el-form-item>
        <el-form-item label="原因" prop="Cause" v-if="dlg.title === '审核'">
          <el-input v-model="formData.Cause"></el-input>
        </el-form-item>
        <el-button v-show="dlg.title === '新增厂商资质审核' || dlg.title === '修改'" style="width:100px;float:right"
                   type="primary" @click="handleSubmit">提交
        </el-button>
        <el-button v-show="dlg.title === '审核'" style="float:right" size="mini" type="primary"
                   @click="handleQualify(false)">不通过
        </el-button>
        <el-button v-show="dlg.title === '审核'" style="float:right" size="mini" type="primary"
                   @click="handleQualify(true)">通过
        </el-button>
      </el-form>
      <el-dialog
        width="30%"
        title="预览"
        :visible.sync="dlg.innerVisible"
        append-to-body>
        <el-image
          :src="dlg.innerImageUrl"
          fit="contain"
        >
          <div slot="error">
            加载失败
          </div>
        </el-image>
      </el-dialog>
    </el-dialog>
    <!-- 记录界面 -->
    <el-dialog
      :title="dlg.title"
      :visible.sync="dlg.visible4History"
      stripe
      width="35%"
      @close="handleClose"
    >
      <el-table
        :data="history.content"
        size="mini"
        border
        highlight-current-row
        v-loading="dialogLoading"
        :max-height="clientHeight"
        style="width: 100%"
      >
        <el-table-column
          prop="ExternalName"
          label="厂商单位名称"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="AuditAt"
          label="审核时间"
          :formatter="dateFormat"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="Handler"
          label="审核人"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="Result"
          label="审核结果"
          :formatter="StatusFormat"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="Cause"
          label="原因"
          align="center"
          header-align="center"
        />
      </el-table>
    </el-dialog>
  </main>
</template>

<script>
import {mapState} from 'vuex'
import {
  join,
  getPname,
  stg
} from '@/util/index'
import conf from '@/config'

export default {
  name: 'Manufacturer',
  data() {
    var validateFileList = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error('请上传附件'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      dialogLoading: false,
      filter: {
        page: 1,
        size: 20,
        pageSize: [20, 30, 50],
        param: {
          Status: '',
          submitter: '',
          submit_date: null
        }
      },
      dlg: {
        title: '',
        visible: false,
        visible4History: false,
        innerVisible: false,
        innerImageUrl: ''
      },
      history: {
        content: [],
        total: 0
      },
      datalist: {
        content: [],
        total: 0
      },
      formData: {
        Name: '',
        ModelName: '',
        Remark: '',
        FileList: []
      },
      rules: {
        Name: [
          {required: true, message: '请输入设备供应单位名称', trigger: 'blur'}
        ],
        ModelType: [
          {required: true, message: '请输入设备类型', trigger: 'blur'}
        ],
        ModelName: [
          {required: true, message: '请输入设备型号', trigger: 'blur'}
        ],
        FileList: [
          {required: true, validator: validateFileList, trigger: 'blur'}
        ],
        // Cause: [
        //   {required: true, message: '请输入原因', trigger: 'blur'}
        // ]
      },
      errorMsg: null,
      factoryList: [],
      deviceTypeList: [],
      modelTypeList: [],
      submitter: '',
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    ...mapState([
      'clientHeight',
      'customerTree'
    ])
  },
  methods: {
    init() {
      this.submitter = JSON.parse(stg().getItem("user")).Name
      this.$post("admin/deviceTypeList", {}).then(res => {
        this.deviceTypeList = res.content
        this.getData()
      })
    },
    getData() {
      this.loading = true
      const payload = {
        StartAt: (this.filter.page - 1) * this.filter.size,
        Size: this.filter.size,
        Param: {}
      }
      if (this.filter.param.Status) {
        payload.Param['status'] = {S: 0, V: `'${this.filter.param.Status}'`}
      }
      if (this.filter.param.submitter) {
        payload.Param['submitter'] = {S: 0, V: this.filter.param.submitter}
      }
      if (this.filter.param.submit_date) {
        payload.Param['submit_date'] = {
          S: 11,
          V: `'${this.filter.param.submit_date[0]}', '${this.filter.param.submit_date[1]}'`
        }
      }
      this.$post('admin/listBusiness', payload).then(res => {
        this.datalist = Object.assign({}, res)
        this.loading = false
      })
    },
    ifDisableQualify(status, submitterId) {
      if (status !== 'WAIT_TO_QUALIFY') {
        return true
      }
      const user = JSON.parse(stg().getItem('user'))
      if (user.Id === '1') {
        return false
      }
      if (user.Id === submitterId) {
        return true
      }
    },
    customerFormatter(r, c, v) {
      return getPname(this.customerTree, v, 'Org')
    },
    getHistory(id) {
      this.dialogLoading = true
      const payload = {
        id: id,
        type: 'Business'
      }
      this.$get('admin/listRecord', payload).then((res) => {
        this.history = Object.assign({}, res)
        this.dialogLoading = false
      })
    },
    handleQualify(isPass) {
      const payload = Object.assign({}, this.formData)
      payload['Status'] = isPass ? 'QUALIFIED' : 'UNQUALIFIED'
      this.$post('admin/saveBusiness', payload).then(() => {
        this.getData()
        this.dlg.visible = false
      })
    },
    upload() {
      return conf.uploadApi + '/uploadToOss'
    },
    handleSubmit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          const payload = Object.assign({}, this.formData)
          payload.Status = "WAIT TO OUALIFY"
          this.$post('admin/saveBusiness', payload).then((res) => {
            this.$message({
              message: res.Msg,
              duration: 1.5
            })
            this.dlg.visible = false
          })
        } else {
          this.$message({
            type: 'error',
            message: res.Msg,
            duration: 1.5
          })
          return false
        }
      })
    },
    handleShowDialog(type, row) {
      switch (type) {
        case 'ADD':
          this.dlg.title = '新增厂商资质审核'
          break
        case 'EDIT':
          this.dlg.title = '修改'
          this.formData = Object.assign({}, row)
          break
        case 'CHECK':
          this.dlg.title = '查看'
          this.formData = Object.assign({}, row)
          break
        case 'QUALIFY':
          this.dlg.title = '审核'
          this.formData = Object.assign({}, row)
          break
        case 'HISTORY':
          this.dlg.title = '记录'
          this.dlg.visible4History = true
          this.getHistory(row.Id)
          return
        default:
          this.dlg.title = ''
      }
      this.dlg.visible = true
    },
    handleFileSuccess(res, file) {
      this.formData.FileList.push({name: file.name, type: file.raw.type, url: res.Data})
    },
    handleFileRemove(file, fileList) {
      this.formData.FileList.forEach((i, idx) => {
        if (i.url === file.url) {
          this.formData.FileList.splice(idx, 1)
        }
      })
    },
    handleFilePreview(file) {
      if (file.type === 'application/pdf') {
        const ele = document.createElement('a')
        ele.target = '_blank'
        ele.href = file.url
        ele.ref = 'opener'
        ele.style.display = 'none'
        ele.click()
        ele.remove()
      } else {
        this.dlg.innerImageUrl = file.url
        this.dlg.innerVisible = true
      }
    },
    handleFileExceed(files, fileList) {
      this.$message.warning(`文件数量限制在4张`)
    },
    beforeFileRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    /**
     * @description dialog关闭事件
     */
    handleClose() {
      this.filter.page = 1
      this.dlg.title = ''
      this.formData = {
        Name: '',
        Remark: '',
        FileList: [],
        SubmitDate: null
      }
      this.$refs['upload'].uploadFiles = []
      this.getData()
    },
    /**
     * @description 审核状态格式化
     * @return {string}
     */
    StatusFormat(r, c) {
      switch (r[c.property]) {
        case 'WAIT_TO_QUALIFY':
          return '待审核'
        case 'QUALIFIED':
          return '已通过'
        case 'UNQUALIFIED':
          return '未通过'
        default:
          return ''
      }
    },
    dateFormat(r, c) {
      return new Date(r[c.property]).toLocaleDateString('zh-CN').replaceAll('/', '-')
    },

    handlePageChange(val, field) {
      this.filter[field] = val
      this.getData()
    },

    handleDelete(row) {
      this.$get("admin/delBusiness", {id: row.Id}).then(res => {
        console.log(res)
        this.getData()
      })
    }
  }
}
</script>

<style scoped>
::v-deep .el-icon-close-tip {
  display: none !important;
}
</style>
